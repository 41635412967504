// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/.pnpm/css-loader@6.11.0_webpack@5.97.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_pagination__q3J9Z {
  width: 100%;
  margin: 30px 0 100px 0;

  > * {
    cursor: pointer;
  }
}

.pagination_pagination__q3J9Z ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.pagination_pagination__q3J9Z li {
  list-style: none;
  width: 35px;
  height: 35px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  &:nth-child(1),
  &:nth-last-child(1) {
    a {
      font-size: 13px;
    }
  }
}

.pagination_pagination__q3J9Z ul > li > a {
  text-decoration-line: none;
}
`, "",{"version":3,"sources":["webpack://./src/PostList/styles/pagination.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,sBAAsB;;EAEtB;IACE,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,uBAAuB;;EAEvB;;IAEE;MACE,eAAe;IACjB;EACF;AACF;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".pagination {\n  width: 100%;\n  margin: 30px 0 100px 0;\n\n  > * {\n    cursor: pointer;\n  }\n}\n\n.pagination ul {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  gap: 5px;\n  cursor: pointer;\n}\n\n.pagination li {\n  list-style: none;\n  width: 35px;\n  height: 35px;\n  border-radius: 8px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: white;\n\n  &:nth-child(1),\n  &:nth-last-child(1) {\n    a {\n      font-size: 13px;\n    }\n  }\n}\n\n.pagination ul > li > a {\n  text-decoration-line: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pagination": `pagination_pagination__q3J9Z`
};
export default ___CSS_LOADER_EXPORT___;
